import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import { useSelector } from 'react-redux';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// not found route
const NotFoundPage = Loadable(lazy(() => import('../views/404')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));
const ProfilePage = Loadable(lazy(() => import('../views/profile')));
const SellerProfilePage = Loadable(lazy(() => import('../views/profile/seller')));
// User Routes
const UserListPage = Loadable(lazy(() => import('../views/user')));
const UserCreatePage = Loadable(lazy(() => import('../views/user/view')));

//Settings

const ReferralSettingPage = Loadable(lazy(() => import('../views/settings/referral/index')));

const TradingHouseSettingPage = Loadable(lazy(() => import('../views/trading-house-setting/index')));

const GameListPage = Loadable(lazy(() => import('../views/settings/game')));
const GameCreatePage = Loadable(lazy(() => import('../views/settings/game/create')));

const CountryListPage = Loadable(lazy(() => import('../views/settings/country')));
const CountryCreatePage = Loadable(lazy(() => import('../views/settings/country/create')));

const TradingHouseListPage = Loadable(lazy(() => import('../views/trading-house')));
const TradingHouseViewPage = Loadable(lazy(() => import('../views/trading-house/view')));

// Marketplace Category
const MarketPlaceCategoryListPage = Loadable(lazy(() => import('../views/marketplace/category')));
const CreateMarketPlaceCategoryPage = Loadable(lazy(() => import('../views/marketplace/category/create')));

// Marketplace Product
const MarketPlaceProductListPage = Loadable(lazy(() => import('../views/marketplace/product')));
const CreateMarketPlaceProductPage = Loadable(lazy(() => import('../views/marketplace/product/create')));
const ImportProductPage = Loadable(lazy(() => import('../views/marketplace/import')));

// Order
const OrderListPage = Loadable(lazy(() => import('../views/order')));
const ViewOrderPage = Loadable(lazy(() => import('../views/order/view')));

//Seller

// Order
const SellerListPage = Loadable(lazy(() => import('../views/seller')));
const CreateSellerPage = Loadable(lazy(() => import('../views/seller/create')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    const account = useSelector((state) => state.account);

    const allowedRoutes = account?.user?.role === "admin" ? [
        { path: '/dashboard/default', component: DashboardDefault },
        { path: '/utils/util-typography', component: UtilsTypography },
        { path: '/utils/util-color', component: UtilsColor },
        { path: '/utils/util-shadow', component: UtilsShadow },
        { path: '/icons/tabler-icons', component: UtilsTablerIcons },
        { path: '/icons/material-icons', component: UtilsMaterialIcons },
        { path: '/sample-page', component: SamplePage },
        { path: '/profile', component: ProfilePage },
        { path: '/user-list', component: UserListPage },
        { path: '/user-create', component: UserCreatePage },
        { path: '/user-view/:id', component: UserCreatePage },
        { path: '/referral-settings', component: ReferralSettingPage },
        { path: '/trading-house-settings', component: TradingHouseSettingPage },
        { path: '/game-list', component: GameListPage },
        { path: '/game-create', component: GameCreatePage },
        { path: '/game-edit/:id', component: GameCreatePage },
        { path: '/country-list', component: CountryListPage },
        { path: '/country-create', component: CountryCreatePage },
        { path: '/country-edit/:countryCode', component: CountryCreatePage },
        { path: '/trading-house-list', component: TradingHouseListPage },
        { path: '/trading-house-view/:id', component: TradingHouseViewPage },
        { path: '/marketplace-category-list', component: MarketPlaceCategoryListPage },
        { path: '/marketplace-category-create', component: CreateMarketPlaceCategoryPage },
        { path: '/marketplace-category-edit/:id', component: CreateMarketPlaceCategoryPage },
        { path: '/marketplace-product-list', component: MarketPlaceProductListPage },
        { path: '/marketplace-product-create', component: CreateMarketPlaceProductPage },
        { path: '/marketplace-product-edit/:id', component: CreateMarketPlaceProductPage },
        { path: '/order-list', component: OrderListPage },
        { path: '/order-view/:id', component: ViewOrderPage },
        { path: '/seller-list', component: SellerListPage },
        { path: '/seller-create', component: CreateSellerPage },
        { path: '/seller-edit/:id', component: CreateSellerPage },
        { path: '/marketplace-product-import', component: ImportProductPage }
    ] : account?.user?.role === "seller" ? [
        { path: '/dashboard/default', component: DashboardDefault },
        { path: '/marketplace-product-list', component: MarketPlaceProductListPage },
        { path: '/marketplace-product-create', component: CreateMarketPlaceProductPage },
        { path: '/marketplace-product-edit/:id', component: CreateMarketPlaceProductPage },
        { path: '/order-list', component: OrderListPage },
        { path: '/order-view/:id', component: ViewOrderPage },
        { path: '/seller-profile', component: SellerProfilePage },
    ] : [];

    return (
        <AuthGuard>
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    {allowedRoutes.map(({ path, component }) => (
                        <Route key={path} exact path={path} component={component} />
                    ))}
                    <Route path="*" component={NotFoundPage} />
                </Switch>
            </MainLayout>
        </AuthGuard>

    );
};

export default MainRoutes;
