// assets
import { IconUser, IconBinaryTree,IconDeviceGamepad2, IconWorld } from '@tabler/icons';

// constant
const icons = {
    IconUser:IconUser,
    IconBinaryTree: IconBinaryTree,
    IconDeviceGamepad2: IconDeviceGamepad2,
    IconWorld: IconWorld
};

export const settings = {
    id: 'setting-pages',
    title: 'Settings',
    type: 'group',
    roles: ['admin'],
    children: [{
            id: 'trading-house-settings',
            title: 'Trade Settings',
            type: 'item',
            url: '/trading-house-settings',
            icon: icons['IconBinaryTree'],
            roles: ['admin'],
            breadcrumbs: false
        },
        {
            id: 'game-settings',
            title: 'API Access',
            type: 'item',
            url: '/game-list',
            icon: icons['IconDeviceGamepad2'],
            roles: ['admin'],
            breadcrumbs: false
        },
        {
            id: 'country-settings',
            title: 'Countries',
            type: 'item',
            url: '/country-list',
            icon: icons['IconWorld'],
            roles: ['admin'],
            breadcrumbs: false
        },
        
    ]
};
